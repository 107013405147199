<template>
  <!-- 操作按钮 -->
  <el-table-column
    :label="$t('libsSz.key13')"
    :width="operateWidth"
    fixed="right"
  >
    <template slot-scope="scope">
      <template v-for="(item, index) in operateList">
        <!-- <template v-if="item.slotName">
          <slot :name="item.slotName" v-bind="scope" :item="item" />
        </template> -->
        <template v-if="item.confirm">
          <m-confirm-btn
            v-if="!item.hasOwnProperty('condition') || item.condition(scope)"
            :key="index"
            :label="item.label"
            :message="item.confirmMessage"
            type="text"
            :confirm-class="'mr10 ml0 f12 ' + (item.customClass || '')"
            @sureClick="() => _operateClick(item.event, scope)"
          />
        </template>
        <template v-else>
          <el-button
            v-if="!item.hasOwnProperty('condition') || item.condition(scope)"
            :key="index"
            v-loading="getItemLoading(item, scope)"
            :disabled="getItemDisabled(item, scope)"
            type="text"
            class="mr10 ml0 f12"
            :class="[item.customClass]"
            @click="() => _operateClick(item.event, scope)"
          >
            {{ item.label || item.getLabel && item.getLabel(scope.row) }}
          </el-button>
        </template>
      </template>
    </template>
  </el-table-column>
</template>
<script>

export default {
  props: {
    extendConfig: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  computed: {
    operateList() {
      const { operate = [], sortCondition } = this.extendConfig
      const list = [...operate]
      if (sortCondition) {
        list.unshift(
          { event: 'asc_order', label: this.$t('lang.gles.strategy.moveUp') },
          { event: 'des_order', label: this.$t('lang.gles.strategy.moveDown') }
        )
      }
      return list
    },

    // 自适应宽度
    operateWidth() {
      const { operate = [], sortCondition, operateWidth } = this.extendConfig
      return operateWidth || operate.length * 70 + Number(!!sortCondition) * 110
    }
  },

  watch: {},

  created() {
  },
  methods: {
    // 操作按钮
    _operateClick(event, scope) {
      this.$emit('triggerBtn', { event, scope })
    },
    getItemLoading(item, scope) {
      return typeof item.loading === 'function' ? item.loading(scope) : false
    },
    getItemDisabled(item, scope) {
      return typeof item.disabled === 'function' ? !item.disabled(scope) : false
    }
  }
}
</script>
