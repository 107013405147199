<!--table-tooltip.vue-->
<template>
  <!-- 文案溢出，显示tooltip -->
  <div
    :id="'table-toolTip-' + id"
    class="el-tooltip__popper is-dark mytable-toolTip"
    style="
      transform-origin: center top;
      z-index: 3000;
      position: fixed;
      display: none;
    "
    @mouseleave="mouseleave"
    @mouseenter="mouseenter"
  >
    {{ tableCellTooltipText }}
    <div :id="'toolTip-arrow-' + id" class="popper__arrow" />
  </div>
</template>

<script>
export default {
  name: 'TableTooltip',
  props: {
    /**
     * 组件dom对应id
     */
    id: {
      type: Number,
      default: 1
    },
    tableCellMouse: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  data() {
    return {
      toolDom: null,
      arrowDom: null,
      tableCellTooltipText: null, // 溢出文案
      mouseLeaveVal: {
        tooltip: 0, // 1-鼠标移入，2-鼠标移出，0-无焦点
        cell: false // 单元格
      }
    }
  },

  computed: {},

  watch: {
    mouseLeaveVal: {
      deep: true,
      handler(nv) {
        setTimeout(() => {
          if (nv.cell && nv.tooltip !== 1) {
            this.toogleActiveTooltip(false, this.toolDom)
          }
        }, 100)
      }
    },
    tableCellMouse: {
      deep: true,
      handler(nv, ov) {
        if (nv.hidden) {
          this.hiddenTooltip()
        } else if (nv.row && !nv.hidden) {
          this.showTooltip(nv.cellDom)
        }
      }
    }
  },

  created() {},

  mounted() {
    this.$nextTick(() => {
      this.toolDom = document.getElementById(`table-toolTip-${this.id}`)
      this.arrowDom = document.getElementById(`toolTip-arrow-${this.id}`)
      // 将组件添加到body下
      const body = document.querySelector('body')
      if (body.append) {
        body.append(this.$el)
      } else {
        body.appendChild(this.$el)
      }
    })
  },
  destroyed() {
    if (this.toolDom) {
      document.body.removeChild(this.toolDom)
    }
  },
  methods: {
    mouseleave(e) {
      this.mouseLeaveVal.tooltip = 2
    },
    mouseenter(e) {
      this.mouseLeaveVal.tooltip = 1
    },
    // 文字溢出显示tooltip  动态计算top和left
    showTooltip(cell) {
      this.tableCellTooltipText = cell.innerText || cell.textContent
      const textWidth = this.textSize('14px', this.tableCellTooltipText)
      if (textWidth > cell.clientWidth - 25) {
        const cellLeft = cell.getBoundingClientRect().left
        const cellTop = cell.getBoundingClientRect().top
        const toolTextWidth = this.textSize('12px', this.tableCellTooltipText)
        const computedPositonNum = this.hiddenToolPosition(
          toolTextWidth,
          cell.clientWidth,
          cellLeft,
          cellTop
        )
        this.toolDom.style.top = `${computedPositonNum.top}px`
        this.toolDom.style.left = `${computedPositonNum.left}px`
        this.toolDom.style.width = `${computedPositonNum.width}px`
        this.arrowDom.style.left = `${computedPositonNum.arrowLeft}px`
        this.toogleActiveTooltip(true, this.toolDom)
        this.mouseLeaveVal.cell = false
        this.mouseLeaveVal.tooltip = 0
        this.toolDom.style.display = ''
      } else {
        this.toolDom.style.display = 'none'
      }
    },

    // toolTip定位计算
    hiddenToolPosition(txtWidth, cellWidth, cellLeft, cellTop) {
      // const toolHight = Math.ceil((txtWidth + 8) / 310) * 24 + 10
      const toolWidth = txtWidth > 300 ? 350 : txtWidth + 25
      // 12为字体大小 1.2为行高 20为左右padding
      const toolHight = Math.ceil((txtWidth + 20) / toolWidth) * 12 * 1.2 + 20
      const webWidth = document.body.offsetWidth
      let expectLeft = cellLeft - (toolWidth - cellWidth) / 2
      const toolWidthAndLeft = expectLeft + toolWidth
      const expectTop = cellTop - toolHight
      let arrowLeft
      if (toolWidthAndLeft > webWidth) {
        const moreWidth = toolWidthAndLeft - webWidth
        expectLeft -= toolWidthAndLeft - webWidth
        arrowLeft = (toolWidth - moreWidth) / 2 + moreWidth - 6
      } else {
        arrowLeft = toolWidth / 2 - 6
      }
      return { left: expectLeft, top: expectTop, width: toolWidth, arrowLeft }
    },

    hiddenTooltip() {
      this.mouseLeaveVal.cell = true
    },

    // tooltip样式
    toogleActiveTooltip(show, toolDom) {
      if (show) {
        toolDom.classList.add(
          'el-fade-in-linear-enter-active',
          'el-fade-in-linear-enter-to',
          'el-popper'
        )
        setTimeout(() => {
          toolDom.classList.remove(
            'el-fade-in-linear-enter-active',
            'el-fade-in-linear-enter-to'
          )
        }, 500)
        toolDom.style.display = ''
      } else {
        toolDom.classList.add(
          'el-fade-in-linear-leave-active',
          'el-fade-in-linear-leave-to'
        )
        setTimeout(() => {
          toolDom.classList.remove(
            'el-fade-in-linear-leave-active',
            'el-fade-in-linear-leave-to',
            'el-popper'
          )
        }, 500)
        toolDom.style.display = 'none'
      }
    },

    textSize(fontSize, text) {
      const span = document.createElement('span')
      let width = span.offsetWidth
      span.style.visibility = 'hidden'
      span.style.fontSize = fontSize
      span.style.display = 'inline-block'
      document.body.appendChild(span)
      if (typeof span.textContent !== 'undefined') {
        span.textContent = text
      } else {
        span.innerText = text
      }
      width = Math.ceil(span.clientWidth)
      document.body.removeChild(span)
      return width
    }
  }
}
</script>

<style lang="scss" scoped>
$blue: #ccc;
$blue-dark-90: #333;
.mytable-toolTip {
  .el-tooltip__popper {
    max-width: 350px !important;
    box-sizing: border-box;
  }
  .popper__arrow {
    bottom: -6px;
    left: 50%;
    margin-right: 3px;
    border-bottom-width: 0;
    border-top-color: $blue;
    &::after {
      border-bottom-width: 0;
      border-top-color: $blue-dark-90;
      bottom: 1px;
      margin-left: -6px;
    }
  }
}
</style>
